import React from "react";

function TempRecruitment() {
    return (
        <div className="relative pt-40 md:pt-20 z-10 min-h-screen flex flex-col justify-center items-center gap-16 md:gap-8">
            <h1 className="md:text-8xl text-4xl font-bold mb-32 text-slate-100 text-center">RECRUITMENT</h1>
            <p className="md:text-4xl text-2xl font-bold text-slate-100 text-center md:w-3/5">We are currently recruiting!</p>
            <button className=" bg-mainBlue hover:bg-slate-700 text-white font-bold py-5 px-7 rounded">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSemdrxUYjYxMEeFK1XY10t7eGDfz3Q3ahfbdDYpTEyOIU3Gnw/viewform" className="text-white text-2xl font-semibold" target="_blank" rel="noopener noreferrer">Apply Here</a>
            </button>
            <p className="md:text-4xl text-2xl font-bold text-slate-100 mt-8 text-center md:w-3/5">Check out our social media for more info!</p>
        </div>
    );
}

export default TempRecruitment;